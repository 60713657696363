.gallery-section{
    padding: 8.8rem 0;
    text-align: center;
    background-color: var(--secondary-color);
    h2{
        margin-bottom: 5.8rem;
        color: var(--white-color);
    }
    
    a{
        text-decoration: none;
    }
    .grid-gallery{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1.8rem;
        column-gap: 1.8rem;
        margin-bottom: 5.8rem;
        figure{
            text-align: center;            
            img{
                display: block;
                margin: 0 auto;
            }
        }
    }
}

// Small devices 
@media (min-width: 576px) {
    .gallery-section{
        .grid-gallery{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

// Large devices
@media (min-width: 992px) {
    .gallery-section{
        .grid-gallery{
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
