.gallery-section {
  padding: 8.8rem 0;
  text-align: center;
  background-color: var(--secondary-color);
}
.gallery-section h2 {
  margin-bottom: 5.8rem;
  color: var(--white-color);
}
.gallery-section a {
  text-decoration: none;
}
.gallery-section .grid-gallery {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.8rem;
  column-gap: 1.8rem;
  margin-bottom: 5.8rem;
}
.gallery-section .grid-gallery figure {
  text-align: center;
}
.gallery-section .grid-gallery figure img {
  display: block;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .gallery-section .grid-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .gallery-section .grid-gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}